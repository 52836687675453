import React, {useCallback, useEffect, useRef, useState} from 'react'
import CommentEditor from './commentEditor/CommentEditor'
import CommentList from './CommentList'
import {
    getCommentsForCard,
    getMemberById,
    getMembershipsForBoard,
    getMembersSubscriptionForNotifications,
    getOrganizationMembersOnCurrentBoard,
    markCommentAsRead,
    uniqueMemberCalculator,
} from '../modules/Persistence'
import CommentData from '../types/CommentData'
import {AnnouncementPricing} from './announcement/AnnouncementPricing'
import {renderTrelloContextMissing, TrackActionEvent} from 'trello-shared-resources/dist'
import {useStyles} from './ThreadedCommentsDisplayStyle'
import GetStarted from './emailNotifications/miniOnboarding/GetStarted'
import SettingsMenuButton from './settings/settingsMenuButton/SettingsMenuButton'
import Member from '../types/Member'
import {CommentsForCardResult} from '../types/CommentsForCardResult'
import {sizeToContent, throwError} from '../modules/UIUtils'
import {Skeleton} from 'antd'
import {processComments} from '../modules/CommentService'
import {useErrorHandler} from 'react-error-boundary'
import {ReadonlyWarning} from './readonlyWarning/ReadonlyWarning'
import ErrorStep from "trello-shared-resources/dist/components/onboarding/error/ErrorStep";


const ThreadedCommentsDisplay = (props: { licenseDetails: any }) => {

    const classes = useStyles()

    const [isLoading, setIsLoading] = useState(true)
    const [comments, setComments] = useState<Array<CommentData>>([])
    const [refreshTimeout, setRefreshTimeout] = useState<any>(undefined)
    const [orahUser, setOrahUser] = useState<any>(undefined)
    const [notLoggedInOrahUser, setNotLoggedInOrahUser] = useState<any>(false)
    const [boardAndOrganizationMembers, setBoardAndOrganizationMembers] = useState<Array<Member>>([])
    const commentEditorRef = useRef<HTMLTextAreaElement | null>(null)
    const [commentIdToScroll, setCommentIdToScroll] = useState<string | undefined>(undefined)
    const [handleErrorRemoveComment, setHandleErrorRemoveComment] = useState<any>(undefined)
    const handleError = useErrorHandler()

    const members = useRef<any>([])
    const licenseDetails = props.licenseDetails
    const trelloIframeContext = licenseDetails.trelloIframeContext

    const updateMembers = useCallback(async (updatedBoardAndOrganizationMembers: Array<Member>) => {
        await getMembersSubscriptionForNotifications(trelloIframeContext, updatedBoardAndOrganizationMembers)
        members.current = updatedBoardAndOrganizationMembers
        setBoardAndOrganizationMembers(updatedBoardAndOrganizationMembers)
    }, [members, trelloIframeContext])

    /**
     * Search a member on the board members list and if it doesn't exists, search it on the API and store it to avoid repeated requests
     * @param memberId
     */
    const searchAndStoreMember = useCallback(async (memberId: string) => {
        if (!members.current || members.current.length === 0) return
        let boardMember = members.current.find((boardMember: Member) => boardMember.id === memberId)
        if (!boardMember) {
            boardMember = await getMemberById(licenseDetails, memberId)
            const updatedBoardAndOrganizationMembers = [...members.current, boardMember].reduce(uniqueMemberCalculator, [])
            await updateMembers(updatedBoardAndOrganizationMembers)
        }
        return boardMember
    }, [members, updateMembers, licenseDetails])


    /**
     * Scroll in the view to a comment that was stored in the local storage
     */
    const scrollToComment = () => {
        // we cannot use localStorage in incognito mode
        if (navigator.cookieEnabled) {
            const commentId = localStorage.getItem('commentId') || ''
            const commentElement = document.getElementById(commentId)
            if (commentId != null && commentElement != null) {
                setCommentIdToScroll(commentId)
                commentElement.scrollIntoView()
            }
        }
    }

    /**
     * Given the given comments, process them, store them on the state and update the iframe size to show all
     * @param comments object obtained from the response
     * @param members (optional) Member array to process comments. If it's not given, it will use the boardAndOrganizationMembers state
     */
    const processAndStoreComments = useCallback(async (comments: CommentsForCardResult) => {
        const proccessedComments = await processComments(licenseDetails, comments.data, searchAndStoreMember)
        setComments(proccessedComments)
        setTimeout(() => {
            sizeToContent(trelloIframeContext)
            scrollToComment()
        }, 200)
    }, [licenseDetails, trelloIframeContext, searchAndStoreMember])

    const getComments = useCallback(async () => {
        const comments = await getCommentsForCard(licenseDetails)
        await processAndStoreComments(comments)
    }, [licenseDetails, processAndStoreComments])

    /**
     * Clear local storage comment scrolled
     */
    function clearCommentScrolled() {
        if (navigator.cookieEnabled && localStorage.getItem('commentId')) {
            localStorage.removeItem('commentId')
        }
    }

    /**
     * Get organization and board members, comments from database, process and store all data
     */
    const getMembersAndComments = useCallback(async () => {
        const boardId = trelloIframeContext.getContext().board
        let boardAndOrganizationMembersFromAPI: Member[] = []
        if (!members.current || members.current.length === 0) {
            let [boardMembers, organizationMembers, comments] = await Promise.all([
                getMembershipsForBoard(licenseDetails, boardId),
                getOrganizationMembersOnCurrentBoard(licenseDetails, searchAndStoreMember),
                getCommentsForCard(licenseDetails)
            ])
            boardAndOrganizationMembersFromAPI = [...organizationMembers, ...boardMembers]
            const uniqueMembersToAdd = [...members.current, ...boardAndOrganizationMembersFromAPI].reduce(uniqueMemberCalculator, [])
            await updateMembers(uniqueMembersToAdd)

            await processAndStoreComments(comments)
            setIsLoading(false)
        } else await getComments()
        if (refreshTimeout) clearTimeout(refreshTimeout)
        setRefreshTimeout(setTimeout(() => {
            getMembersAndComments().then(() => clearCommentScrolled()).catch((error) => throwError(() => handleError(error), trelloIframeContext))
        }, 10_000))
    }, [licenseDetails, getComments, members, updateMembers, processAndStoreComments, trelloIframeContext, refreshTimeout, searchAndStoreMember, handleError])

   useEffect(() => {

           if (handleErrorRemoveComment) {
               setHandleErrorRemoveComment(undefined)
               throwError(() => handleError(handleErrorRemoveComment), trelloIframeContext)
           }

           if (licenseDetails.isAuthorized) {
               getMembersAndComments().catch((error) => throwError(() => handleError(error), trelloIframeContext))
               markCommentAsRead(trelloIframeContext).catch((error) => {
                   console.log(error)
                   throwError(() => handleError(error), trelloIframeContext)
               })
           }

           if (!orahUser) {
               licenseDetails.getUser().then((user: any) => {
                   setOrahUser(user)
                   setNotLoggedInOrahUser(user === undefined)
               }).catch((error: any) => throwError(() => handleError(error), trelloIframeContext))
           }

       },  // eslint-disable-next-line
       [licenseDetails.isAuthorized, trelloIframeContext, orahUser, notLoggedInOrahUser, handleErrorRemoveComment])


    if (!trelloIframeContext) {
        return renderTrelloContextMissing()
    }

    if (!licenseDetails.isAuthorized) {
        licenseDetails.smallErrorMessageWindow = true
        return <ErrorStep licenseDetails={licenseDetails}/>
    }

    if (!orahUser && !notLoggedInOrahUser && isLoading) {
        return <Skeleton active={true}/>
    }

    const upgradeHandler = () => {
        const trelloContextInfo = trelloIframeContext.getContext()
        TrackActionEvent('Settings link', trelloContextInfo, {
            board_id: trelloContextInfo.board,
            member_id: trelloContextInfo.member,
            action: 'upgrade_link'
        })
        trelloIframeContext.modal({
            url: notLoggedInOrahUser ? './index.html?apptype=settings' : './index.html?apptype=billingSettings',
            fullscreen: true,
            resizable: false,
            title: `${process.env.REACT_APP_TRELLO_APP_NAME} Settings`,
            accentColor: '#EBEDF0'
        })
    }

    return (
        <div className={classes.appContainer} id="content">
            <SettingsMenuButton licenseDetails={licenseDetails}/>
            <GetStarted licenseDetails={licenseDetails}/>
            <ReadonlyWarning licenseDetails={licenseDetails} upgradeHandler={upgradeHandler}/>
            <AnnouncementPricing licenseDetails={licenseDetails} notLoggedInOrahUser={notLoggedInOrahUser}/>
            <CommentEditor licenseDetails={licenseDetails} getComments={getComments}
                           boardAndOrganizationMembers={boardAndOrganizationMembers} setComments={setComments}
                           comments={comments}
                           defaultText={'Write a comment...'} ref={commentEditorRef}
            />
            <CommentList licenseDetails={licenseDetails} comments={comments} getComments={getComments}
                         setHandleErrorRemoveComment={setHandleErrorRemoveComment}
                         setComments={setComments}
                         boardAndOrganizationMembers={boardAndOrganizationMembers}
                         searchAndStoreMember={searchAndStoreMember} commentIdToScroll={commentIdToScroll}/>
        </div>)
}

export default ThreadedCommentsDisplay